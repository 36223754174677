import React, { useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import LeftNavbar from '../Layout/LeftNavbar'
import Footer from '../Layout/Footer'
import { getToken, isAdmin } from '../.comman';
import axios from 'axios';
import { getAdminDashBoard } from '../../.endpoint';
import { Link } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'




function Dashboard() {
    document.title = "Law Calibration -Dashboard";
    const [loading, setLoading] = useState(false);
    const [dashboardData, setDashboarddata] = useState([]);
    const [leadsData, setLeadsData] = useState([]);
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    useEffect(() => {
        setLoading(true)
        axios.get(getAdminDashBoard, { headers: headers })
            .then((res) => {
                setDashboarddata(res.data.data);
                setLeadsData(res.data.data.LatestLeads);
                setLoading(false)
            })
            .catch((error) => { console.log(error); })
    }, [])
    return (
        <div id="wrapper">
            <LeftNavbar />

            <div id="page-wrapper" className="dashboard gray-bg">
                <Navbar />
                <div className="wrapper wrapper-content">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <span className="label label-danger pull-right">Monthly</span>
                                    <h5>Leads This Month</h5>
                                </div>
                                <div className="ibox-content">
                                    <h1 className="no-margins">{dashboardData.LeadThisMonth ? (dashboardData.LeadThisMonth) : (loading ? <ScaleLoader className='spinner' height={30} color="#36d7b7" /> : "0")}</h1>
                                    {/* <div className="stat-percent font-bold text-danger">38% <i className="fa fa-level-down"></i></div> */}
                                    <small>Leads</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <span className="label label-info pull-right">Last Month</span>
                                    <h5>Lead Last Month</h5>
                                </div>
                                <div className="ibox-content">
                                    <h1 className="no-margins">{dashboardData.LeadLastMonth ? (dashboardData.LeadLastMonth) : (loading ? <ScaleLoader className='spinner' height={30} color="#36d7b7" /> : "0")}</h1>
                                    {/* <div className="stat-percent font-bold text-info">98% <i className="fa fa-level-up"></i></div> */}
                                    <small>Leads</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <span className="label label-primary pull-right">Yearly</span>
                                    <h5>Lead This Year</h5>
                                </div>
                                <div className="ibox-content">
                                    <h1 className="no-margins">{dashboardData.LeadThisYear ? (dashboardData.LeadThisYear) : (loading ? <ScaleLoader className='spinner' height={30} color="#36d7b7" /> : "0")}</h1>
                                    {/* <div className="stat-percent font-bold text-navy">78% <i className="fa fa-level-up"></i></div> */}
                                    <small>Leads</small>
                                </div>
                            </div>
                        </div>
                        {isAdmin() && (
                            <div className="col-lg-3">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-success pull-right">Employee</span>
                                        <h5>Sale Person</h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{dashboardData.TotalSalePerson ? (dashboardData.TotalSalePerson) : (loading ? <ScaleLoader className='spinner' height={30} color="#36d7b7" /> : "0")}</h1>
                                        {/* <div className="stat-percent font-bold text-success">38% <i className="fa fa-level-up"></i></div> */}
                                        <small>Active Sale Person</small>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Latest 5 Leads listing Code start*/}
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">

                                    <div className="ibox-content">

                                        <table className="table table-striped table-bordered table-hover dataTables-example" >
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Customer Name</th>
                                                    <th>Point of Contact</th>
                                                    <th>Phone No.</th>
                                                    <th>Email</th>
                                                    <th>City</th>
                                                    <th>Sale Person</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    leadsData.length > 0 ? (
                                                        leadsData.map((item, i) => {
                                                            return (<tr className="gradeC" key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{item.customer_name}</td>
                                                                <td>{item.point_of_contact}</td>
                                                                <td>{item.phone_no}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.city_name}</td>
                                                                <td>{item.sales_man_name}</td>
                                                                <td>{item.status}</td>
                                                                <td><Link to={"/lead-detail/" + item.id} > <button className="btn btn-outline btn-primary dim" type="button"><i className="fa fa-eye"></i></button></Link></td>
                                                            </tr>)
                                                        })
                                                    ) : (
                                                        <tr className="gradeC">
                                                            <td colSpan={9}>{loading ? <p>Loading...</p> : "Data Not available"} </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Latest 5 Leads listing code End*/}
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
