import React, { useEffect, useState } from 'react'
import LeftNavbar from '../Layout/LeftNavbar'
import Navbar from '../Layout/Navbar'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Footer from '../Layout/Footer'
import { useFormik } from 'formik'
import axios from 'axios'
import { editStatus, fetchStatus } from '../../.endpoint'
import { getToken, toastSetting } from '../.comman'
import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup'
// import ToastContainerDefault from '../ToastContainerDefault'

const formValues = {
    name: "",
    status: "1"
};
const EditStatus = () => {
    document.title = "Status Edit | Law Calibration";
    const navigate = useNavigate();
    const param = useParams()
    const [recordId, setRecordId] = useState((param.id ? param.id : 0));
    const [recordDetail, setRecordDetail] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'

    const EditStatusSchemas = yup.object().shape({
        name: yup.string()
            .min(2, 'Status Name must be at least 2 characters')
            .max(50, 'Status Name must be at most 50 characters')
            .matches(stringcheck, 'Please enter valid status name. (Only alphabets are allowed)')
            .required('Please enter status name'),

        is_active: yup.string()
            .required('Please choose status'),
    });

    //Get Status detail
    useEffect(() => {
        axios.get(fetchStatus + `?id=${recordId}`, { headers: headers })
            .then((res) => {
                setRecordDetail(res.data.data);
            })
            .catch((error) => {
                toast.error(error.message, toastSetting())
            })
    }, []);

    //Edit Status function
    const handleOnSubmit = (values, action) => {
        console.log('submitStatus--', values);

        axios({
            method: "PUT",
            url: editStatus,
            data: values,
            headers: headers
        })
            .then(res => {
                console.log('aftersubmit--', res);
                if (res.data.success == true) {
                    toast.success(res.data.message, toastSetting())

                    setTimeout(() => {
                        action.resetForm({ values: formValues });
                        navigate("/status/list");
                    }, 3000)
                } else if (res.data.success === false) {
                    if (res.data.data.name && res.data.data.name.length > 0) {
                        console.log('Data--', res.data);
                        toast.error(res.data.data.name[0], toastSetting())
                        return false;
                    }
                }

            })
            .catch(error => {
                toast.error(error.message, toastSetting());
            });
    };

    const formik = useFormik({
        initialValues: recordDetail,
        validationSchema: EditStatusSchemas,
        enableReinitialize: true,
        onSubmit: handleOnSubmit
    })

    return (
        <>
            <div id="wrapper">
                <LeftNavbar />

                <div id="page-wrapper" className="gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Edit Status</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/status/list'}>Status List</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>Edit</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2">

                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Edit Status Form</h5>
                                        </div>

                                        <div className="ibox-content">
                                            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor='name' className="col-sm-2 control-label">Name <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            id='name'
                                                            name='name'
                                                            className="form-control"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.name && formik.errors.name && (
                                                            <p className='text-danger'>{formik.errors.name}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='is_active' className="col-sm-2 control-label">Status <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <select
                                                            name="is_active"
                                                            className="form-control"
                                                            id="is_active"
                                                            value={formik.values.is_active}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value="">Select Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">InActive</option>
                                                        </select>
                                                        {formik.touched.is_active && formik.errors.is_active && (
                                                            <p className='text-danger'>{formik.errors.is_active}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <div className="col-sm-4 col-sm-offset-2 group-btns">
                                                        <NavLink className="btn btn-white" to={'/status/list'}>Cancel</NavLink>
                                                        <button className="btn btn-primary" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div >
        </>
    )
}
export default EditStatus;
