import Dashboard from "./component/admin/Dashboard";
import Error from "./component/Error";
import ForgotPassword from "./component/ForgotPassword";
import LeadListing from "./component/admin/LeadListing";
import Login from "./component/Login";
import { Route, Routes, } from "react-router-dom";
import RequestForm from "./component/RequestForm";
import Protected from "./component/auth/Protected";
import Logout from "./component/auth/Logout";
import SalePersonList from "./component/admin/SalePersonList";
import Leaddetail from "./component/admin/Leaddetail";
import AddSalePerson from "./component/admin/AddSalePerson";
import ThanksPage from "./component/ThanksPage";
import EditSalePerson from "./component/admin/EditSalePerson";
import ResetPassword from "./component/ResetPassword";
import TestFile from "./component/testFile";
import RequestForm2 from "./component/RequestForm2";
//Status Management
import StatusList from "./component/status/StatusList";
import StatusAdd from "./component/status/AddStatus";
import StatusEdit from "./component/status/EditStatus";
import Profile from "./component/admin/Profile.js";


function App() {

  return (

    <Routes>
      <Route path="/" element={<Login />} exact={true} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/get-quote-form" element={<RequestForm />} />
      <Route path="/thanks" element={<ThanksPage />} />
      <Route path="*" element={<Error />} />
      <Route path="/test" element={<TestFile />} />
      <Route path="/test2" element={<RequestForm2 />} />

      {/* Admin routes */}
      <Route path="/logout" element={<Protected Component={Logout} />} />
      <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
      <Route path="/lead-listing" element={<Protected Component={LeadListing} />} />
      <Route path="/lead-detail/:id" element={<Protected Component={Leaddetail} />} />
      <Route path="/sale-person-list" element={<Protected Component={SalePersonList} />} />
      <Route path="/sale-person-edit/:id" element={<Protected Component={EditSalePerson} />} />
      <Route path="/add-sale-person" element={<Protected Component={AddSalePerson} />} />
      <Route path="/profile" element={<Protected Component={Profile} />} />
      {/* Status Routes */}
      <Route path="/status/list" element={<Protected Component={StatusList} />} />
      <Route path="/status/add" element={<Protected Component={StatusAdd} />} />
      <Route path="/status/edit/:id" element={<Protected Component={StatusEdit} />} />



    </Routes>

  );
}

export default App;
