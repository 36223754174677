import * as yup from 'yup'
const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'
const stringcheckEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
// const commentExp = '^(?!.*[@~!#$>%<&^+=_-`*])(?!. *[0-9._]$)[a-zA-Z]';
const commentExp = /<script.*?>.*?<\/script>/gi;

const requestQupteForm = yup.object().shape({
    // serviceType: yup.string(),
    customerName: yup
        .string().matches(stringcheck, 'Please enter your valid customer name. (Only alphabets are allowed)')
        .min(2, 'Customer name must be at least 2 characters')
        .max(60, 'Customer name must be less than 60 characters')
        .test('no-html-tags', 'HTML tags are not allowed', (value) => {
            return !/<[^>]*>/i.test(value);
        })
        .test('no-link', 'Links are not allowed', (value) => {
            return !/https?:\/\/\S+/i.test(value);
        })
        .required('Please enter customer name'),


    pointOfContact: yup
        .string().matches(stringcheck, 'Please enter your valid point of contact. (Only alphabets are allowed)')
        .min(2, 'Point of contact must be at least 2 characters')
        .max(60, 'Point of contact must be less than 60 characters')
        .test('no-html-tags', 'HTML tags are not allowed', (value) => {
            return !/<[^>]*>/i.test(value);
        })
        .test('no-link', 'Links are not allowed', (value) => {
            return !/https?:\/\/\S+/i.test(value);
        })
        .required('Please enter point of contact'),

    pocAddress: yup
        .string()
        .test('no-html-tags', 'HTML tags are not allowed', (value) => {
            return !/<[^>]*>/i.test(value);
        })
        .test('no-link', 'Links are not allowed', (value) => {
            return !/https?:\/\/\S+/i.test(value);
        })
        .required('Please enter point of contact address'),

    zipCode: yup
        .string()
        .min(5, 'Please enter valid 5 digit zip code')
        .max(5, 'Please enter valid 5 digit zip code')
        .matches('[0-9]{5}', 'Please enter valid 5 digit zip code. (Only numeric are allowed)')
        .required('Please enter 5 digit zip code'),

    city: yup
        .string().matches(stringcheck, 'Please enter your valid city name. (Only alphabets are allowed)')
        .min(2, 'City name must be at least 2 characters')
        .max(100, 'City name must be less than 100 characters')
        .test('no-html-tags', 'HTML tags are not allowed', (value) => {
            return !/<[^>]*>/i.test(value);
        })
        .test('no-link', 'Links are not allowed', (value) => {
            return !/https?:\/\/\S+/i.test(value);
        })
        .required('Please enter city name'),

    state: yup
        .string().required('Please select state'),

    email: yup
        .string()
        .matches(stringcheckEmail, 'Please enter valid email')
        .email('Please enter a valid email')
        .required('Please enter your email'),

    phoneNumber: yup
        .string()
        .max(10, 'Please enter valid 10 digit phone number')
        .matches('[0-9]{10}', 'Please enter valid 10 digit phone number. (Only numeric are allowed)')
        .required('Please enter your 10 digit phone number'),

    // listProduct: yup.string(),

    comments: yup
        // .string().matches(commentExp, 'Please enter valid comment. (Special characters are not allowed)')
        .string()
        .max(250, 'Maximum comment length is 250 characters')

        .test('no-html-tags', 'HTML tags are not allowed', (value) => {
            // Check if the value contains any HTML tags
            return !/<[^>]*>/i.test(value);
        })
        .test('no-link', 'Links are not allowed', (value) => {
            return !/https?:\/\/\S+/i.test(value);
        }),
    // .nullable(),
    // repairCheck: yup.array().required('At least one repair service must be selected'),//.min(1, 'At least one repair service must be selected')

    expeditedOption: yup.string(),
    joinSubscriberList: yup.string(),

    // uploadProductlist: yup
    //     .mixed()
    //     .nullable()
    //     .test("FILE_SIZE", "Only documents up to 2MB are permitted.",
    //         (value) => value && value.size < 2100000)
    //     .test("FILE_TYPE", "invalid File.",
    //         (value) => value && ['application/pdf'].includes(value.type)),


});

export default requestQupteForm;
