import React, { useState, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormik, Formik } from 'formik'
import * as yup from 'yup'
import requestFormSchemas from "../schemas/index"
import axios from 'axios'
import { getCountry, leadSubmit, siteKey } from '../.endpoint'
import { ClockLoader } from 'react-spinners';
import Swal from 'sweetalert2'
import AddRemoveInputField from './AddRemoveInputField'
// import { Formik } from 'formik'
const formValues = {
    serviceType: "1",
    customerName: null,
    cAddress: null,
    pointOfContact: null,
    pocAddress: null,
    zipCode: null,
    city: "",
    state: null,
    country: "231",
    email: null,
    phoneNumber: null,
    listProduct: "",
    uploadProductlist: null,
    comments: "",
    expeditedOption: "1",
    joinSubscriberList: "1",
};


const RequestForm2 = () => {
    document.title = "Law Calibration";
    const Redirect = useNavigate();
    const [option, setOption] = useState(1);
    const [isActive, setIsActive] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const [subscriber, setSubscriber] = useState(1);
    const [service, setService] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userFiles, setUserFiles] = useState([]);
    const [verfied, setVerfied] = useState(false);
    const [activeMenuSecond, setActiveMenuSecond] = useState(false);
    const handleMenuSecond = () => {
        setActiveMenuSecond(!activeMenuSecond)
    }
    const onCaptchaChange = (value) => {
        setVerfied(true);
        // console.log('captcha value', value);
    }
    const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [conId, setConId] = useState('231');
    const handleMenu = () => {
        setActiveMenu(!activeMenu)
    }
    //Call api to get country
    useEffect(() => {
        setLoading(true);
        const getcountry = async () => {
            const rescountry = await fetch(getCountry);
            const rescon = await rescountry.json();
            setCountry(rescon.data);
        }
        getcountry();
    }, []);

    // handle selected country id
    const countryHandler = (event) => {
        const getCountryId = event.target.value;
        setConId(getCountryId);
    }

    //call api to get state
    useEffect(() => {
        const getState = async () => {
            const resState = await fetch(getCountry + '?id=' + conId);
            const resSt = await resState.json();
            if (resSt.data.length > 0) {
                setState(resSt.data[0].state_list)
            }
        }
        getState();
        setLoading(false)
    }, [conId])

    // form handling through Formik hook


    useEffect(() => {
        let wrapper = document.querySelectorAll('.col-wrapper select');
        wrapper.forEach(el => {
            el.addEventListener('pointerdown', function () {
                this.parentNode.classList.toggle('active')
            });

            el.addEventListener('blur', function () {
                this.parentNode.classList.remove('active')
            });
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            serviceType: "",
            customerName: "",
            cAddress: "",
            uploadProductlist: [],
        },
        validationSchema: yup.object({
            customerName: yup
                .string().matches(stringcheck, 'Please enter your valid customer name. (Only alphabets are allowed)')
                .min(2, 'Customer name must be at least 2 characters')
                .max(60, 'Customer name must be less than 60 characters')
                .required('Please enter customer name'),

            cAddress: yup
                .string()
                .required('Please enter customer address'),

            uploadProductlist: yup
                .mixed()
                // .nullable()
                .test("FILE_SIZE", "Only documents up to 2MB are permitted.",
                    (value) => value && value.size < 1024 * 1024)
                .test("FILE_TYPE", "invalid File.",
                    (value) => value && ['application/pdf'].includes(value.type)),
        }),
        onSubmit: () => {
            console.log('FormValues', formik.values)
        }
    })



    return (
        <>
            <div className="site-header">
                <div className="container">
                    <nav className="navbar" id="navbar">
                        <button className="nav-menu-btn" onClick={() => setIsActive(true)}><i className='fa fa-bars'></i></button>
                        <NavLink to={'https://develop.stackblue.com/lawcalibration'}>
                            <div className="navbar-brand">
                                <img src="./asset/img/site-logo.png" alt="" />
                            </div>
                        </NavLink>
                        <div class="top_social_2">
                            <ul>
                                <li><a href="https://www.facebook.com/" target="_blank">Facebook <i class="fa fa-facebook-f"></i></a></li>
                                <li><a href="https://in.linkedin.com/" target="_blank">Linkedin <i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>

            <div className={isActive ? 'side-bar active' : 'side-bar'}>
                <div className="side-bar-wrapper">
                    <div className="side-bar-header">
                        <button className='side-bar-close' onClick={() => setIsActive(false)}><i className='fa fa-close'></i></button>
                    </div>
                    <nav className="side-bar-nav">
                        <ul className='sidebar-navbar'>
                            <li>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/'}>Home</NavLink>
                            </li>
                            <li>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/about/'}>About</NavLink>
                            </li>
                            <li className={activeMenu ? "dropdown active" : "dropdown"} onClick={() => handleMenu()}>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/what-we-offer/'}>What We Offer</NavLink>
                                <button className='sub-menu-toggle'><i className='fa fa-angle-down'></i></button>
                                <div className="sub-menu-wrapper">
                                    <ul className='sub-menu'>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/microscopes/'}>Microscopes</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/laboratory-calibration-services/'}>Laboratory Calibration Services</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/onsite-calibration-services/'}>
                                                Onsite Calibration Services
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/validation-services/'}>Validation Services</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/regional-service-center-for-ohaus/'}>Regional Service Center For Ohaus</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/what-we-do/'}>What We Do</NavLink>
                            </li> */}
                            <li className={activeMenuSecond ? "dropdown active" : "dropdown"} onClick={() => handleMenuSecond()}>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/what-we-offer/'}>What We Do</NavLink>
                                <button className='sub-menu-toggle'><i className='fa fa-angle-down'></i></button>
                                <div className="sub-menu-wrapper">
                                    <ul className='sub-menu'>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/biotech/'}>Biotech</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/electronic/'}>Electronic</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/industrial/'}>Industrial</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/dimensional/'}>Dimensional</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/mass-balances/'}>Mass Balances</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/mechanical/'}>Mechanical</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/temperature-humidity/'}>Temperature / Humidity</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/torque/'}>Torque</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/pressure/'}>Pressure</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/testimonials/'}>Testimonials</NavLink>
                            </li>
                            <li>
                                <NavLink to={'https://develop.stackblue.com/lawcalibration/contact-us/'}>Contact Us</NavLink>
                            </li>
                            <li className='common-btn yellow-btn'>
                                <NavLink to={'https://lawcalibration.stackblue.com/get-quote-form'}>Request A Quote</NavLink>
                            </li>
                        </ul>
                    </nav>
                    <ul className="contact-info">
                        <li>
                            <span className="icon">
                                <i className='fa fa-building'></i>
                            </span>
                            <span>
                                <b>Address</b> <br />
                                41 Spring Hill Rd, Saco, ME 04072
                            </span>
                        </li>

                        <li>
                            <span className="icon">
                                <i className='fa fa-phone'></i>
                            </span>
                            <span>
                                <b>Phone</b><br />
                                207-494-7538
                            </span>
                        </li>

                        <li>
                            <span className="icon">
                                <i className='fa fa-envelope'></i>
                            </span>
                            <span>
                                <b>Email</b><br />
                                info@lawcalibration.com
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="req-form-sec">
                {loading ? (
                    <div className='loader-Container'><ClockLoader color="#36d7b7" /></div>
                ) : (
                    <>
                        {/* <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Request Quote Form</h2>
                            </div>
                            <div className="col-lg-2">
                                <h2><Link to={'/'}> <button className="btn btn-primary"> Back to Login </button></Link></h2>
                            </div>
                        </div> */}
                        <div className="req-form-image">
                            <img src="./asset/img/contact-bg.jpg" alt="" />
                        </div>
                        <div className="container">
                            <div className="req-banner-content">
                                <form id='req-form' className="form-horizontal" encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                                    <div className="req-row">
                                        <div className="form-group w-100">
                                            <label htmlFor='subscriber'>Service Type</label>
                                            <div className='radio-group'>
                                                <div className="radio i-checks"><label> <input type="radio" value="1" onChange={(e) => formik.setFieldValue(e.target.value)} name="serviceType" /> <i></i> In-Lab Request</label></div>
                                                <div className="radio i-checks"><label> <input type="radio" value="2" onChange={(e) => formik.setFieldValue(e.target.value)} name="serviceType" /> <i></i>On-Site Request</label></div>
                                                <div className="radio i-checks"><label> <input type="radio" value="3" onChange={(e) => formik.setFieldValue(e.target.value)} name="serviceType" /> <i></i> No Preference</label></div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor='companyName' >Company Name <sup >*</sup></label> */}
                                            <div className='col-wrapper'>
                                                <input type="text" id='customerName' name='customerName' placeholder='Customer Name' className="form-control" value={formik.values.customerName} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {((formik.errors.customerName && formik.touched.customerName) || (formik.errors.customerName && formik.touched.formValues)) ? (<p className='text-danger'>{formik.errors.customerName}</p>) : null}

                                            </div>
                                        </div>

                                        <div className="form-group">
                                            {/* <label htmlFor='cAddress' >cAddress <sup >*</sup></label> */}
                                            <div className='col-wrapper'>
                                                <input name='cAddress' id='cAddress' type="text" placeholder='Address' className="form-control" value={formik.values.cAddress} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {
                                                    ((formik.errors.cAddress && formik.touched.cAddress) || (formik.errors.cAddress && formik.touched.formValues)) ?
                                                        (<p className='text-danger'>{formik.errors.cAddress}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        {/* <div className='form-group'>
                                            <div className='col-wrapper'>
                                                <input name='pointOfContact' id='pointOfContact' type="text" placeholder='Point of Contact' className="form-control" value={values.pointOfContact} onBlur={handleBlur} onChange={handleChange} />
                                                {
                                                    ((errors.pointOfContact && touched.pointOfContact) || (errors.pointOfContact && touched.formValues)) ?
                                                        (<p className='text-danger'>{errors.pointOfContact}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="col-wrapper">
                                                <input name='pocAddress' id='pocAddress' type="text" placeholder='Address' className="form-control" value={values.pocAddress} onBlur={handleBlur} onChange={handleChange} />
                                                {
                                                    ((errors.pocAddress && touched.pocAddress) || (errors.pocAddress && touched.formValues)) ?
                                                        (<p className='text-danger'>{errors.pocAddress}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='col-wrapper'>
                                                <input name='city' id='city' type="text" placeholder='City' className="form-control" value={values.city} onBlur={handleBlur} onChange={handleChange} />
                                                {
                                                    ((errors.city && touched.city) || (errors.city && touched.formValues)) ?
                                                        (<p className='text-danger'>{errors.city}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='col-wrapper'>
                                                <input name='zipCode' id='zipCode' type="text" placeholder='Zip Code' className="form-control" value={values.zipCode} onBlur={handleBlur} onChange={handleChange} />
                                                {
                                                    ((errors.zipCode && touched.zipCode) || (errors.zipCode && touched.formValues)) ?
                                                        (<p className='text-danger'>{errors.zipCode}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="col-wrapper">
                                                <select className="form-control m-b" value={values.state} name="state" onBlur={handleBlur} onChange={handleChange}>
                                                    <option >Select State</option>
                                                    {state.map((getSt, index) => (
                                                        <option key={index} value={getSt.id}>{getSt.state_name}</option>
                                                    ))}
                                                </select>
                                                <div className="icon">
                                                    <i className='fa fa-angle-down'></i>
                                                </div>
                                            </div>
                                            {
                                                ((errors.state && touched.state) || (errors.state && touched.formValues)) ?
                                                    (<p className='text-danger'>{errors.state}</p>)
                                                    : null
                                            }
                                        </div>
                                        <div className='form-group'>
                                            <div className="col-wrapper">

                                                <select className="form-control m-b" name="country" value={conId} onChange={(e) => countryHandler(e)} onBlur={handleBlur} >
                                                    {country.map((getCon, index) => (
                                                        <option key={index} value={getCon.id} > {getCon.country_name}</option>
                                                    ))}

                                                </select>

                                                <div className="icon">
                                                    <i className='fa fa-angle-down'></i>
                                                </div>
                                            </div>
                                            {
                                                ((errors.country && touched.country) || (errors.country && touched.formValues)) ?
                                                    (<p className='text-danger'>{errors.country}</p>)
                                                    : null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <div className="col-wrapper">
                                                <input type="email" className="form-control" placeholder='Email' name="email" id='email' value={values.email} onBlur={handleBlur} onChange={handleChange} />
                                                {
                                                    ((errors.email && touched.email) || (errors.email && touched.formValues)) ?
                                                        (<p className='text-danger'>{errors.email}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-wrapper">
                                                <input name='phoneNumber' id='phoneNumber' type="text" placeholder="Phone Number" className="form-control" value={values.phoneNumber} onBlur={handleBlur} onChange={handleChange} />
                                                {
                                                    ((errors.phoneNumber && touched.phoneNumber) || (errors.phoneNumber && touched.formValues)) ?
                                                        (<p className='text-danger'>{errors.phoneNumber}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                         <AddRemoveInputField /> 
                                        <div className="form-group">
                                            <div className="col-wrapper">
                                                <textarea name="listProduct" className='form-control' id="listProduct" cols="90" rows="6" style={{ resize: "none" }} placeholder="Please list each product on a separate line (include: Manufacturer, Model No., Description) or attach a list of your products using the field below" value={values.listProduct} onBlur={handleBlur} onChange={handleChange} ></textarea>
                                                {
                                                    errors.listProduct ?
                                                        (<p className='text-danger'>{errors.listProduct}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>  */}

                                        <div className="form-group w-100">
                                            <label htmlFor='uploadProductlist' >UPLOAD PRODUCT LIST</label>
                                            <div className="col-wrapper" style={{ borderBottom: "1.5px solid", paddingBlockEnd: '24px' }}>
                                                <input
                                                    type="file"
                                                    style={{ border: 'none' }}
                                                    name='uploadProductlist'
                                                    id='uploadProductlist'
                                                    className="form-control"
                                                    // value={formik.values.uploadProductlist}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => formik.setFieldValue('uploadProductlist', e.target.files[0])} />

                                                {
                                                    ((formik.errors.uploadProductlist && formik.touched.uploadProductlist) || (formik.errors.uploadProductlist && formik.touched.formValues)) ?
                                                        (<p className='text-danger'>{formik.errors.uploadProductlist}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="form-group">
                                            <div className="col-wrapper">
                                                <textarea name="comments" className='form-control' placeholder='Comment' id="comments" cols="90" rows="5" value={values.comments} onBlur={handleBlur} onChange={handleChange} ></textarea>
                                                {
                                                    errors.comments ?
                                                        (<p className='text-danger'>{errors.comments}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group w-100">
                                            <label htmlFor='expeditedOption' >EXPEDITED OPTIONS:</label>
                                            <div className='col-wrapper radio-group'>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setOption(e.target.value) }} value="1" name="expeditedOption" checked={option == '1'} /> <i></i> Multifunction Calibrator Four-Day On Bench (+$350/calibration)</label></div>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setOption(e.target.value) }} value="2" name="expeditedOption" checked={option == '2'} /> <i></i> Same-day (+$200/calibration)</label></div>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setOption(e.target.value) }} value="3" name="expeditedOption" checked={option == '3'} /> <i></i> Overnight (+$140/calibration) </label></div>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setOption(e.target.value) }} value="4" name="expeditedOption" checked={option == '4'} /> <i></i> Two-Day (+$115/calibration) </label></div>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setOption(e.target.value) }} value="5" name="expeditedOption" checked={option == '5'} /> <i></i> Four-Day (+$60/calibration) </label></div>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setOption(e.target.value) }} value="6" name="expeditedOption" checked={option == '6'} /> <i></i> Standard (+$0/calibration) </label></div>
                                                {
                                                    errors.expeditedOption ?
                                                        (<p className='text-danger'>{errors.expeditedOption}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor='subscriber' >JOIN THE ESSCO EMAIL SUBSCRIBER LIST?</label>
                                            <div className='col-wrapper radio-group'>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setSubscriber(e.target.value) }} value="1" name="joinSubscriberList" checked={subscriber == '1'} /> <i></i>Yes</label></div>
                                                <div className="radio i-checks"><label> <input type="radio" onChange={(e) => { setSubscriber(e.target.value) }} value="0" name="joinSubscriberList" checked={subscriber == '0'} /> <i></i>No</label></div>
                                                {
                                                    errors.joinSubscriberList ?
                                                        (<p className='text-danger'>{errors.joinSubscriberList}</p>)
                                                        : null
                                                }
                                            </div>
                                        </div> */}

                                        <div className='form-group'>
                                            <ReCAPTCHA
                                                sitekey={siteKey}
                                                onChange={onCaptchaChange}
                                            />
                                        </div>
                                        <div className="form-group w-100">
                                            <div className="col-wrapper group-btns" style={{ justifyContent: "center" }}>
                                                <Link to={'https://develop.stackblue.com/lawcalibration'}><button className="btn btn-white" >Cancel</button></Link>
                                                <button className="btn btn-primary" type="submit" >Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
                }
            </div >

            <div className="site-footer">
                <div className="container">

                    <div className="footer-widgets">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="footer-widget">
                                    <div className="footer-widget-title">
                                        <h3>Quick Links</h3>
                                    </div>

                                    <div className="footer-widget-content">
                                        <ul className='footer-nav'>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/about/'}><li>About</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/what-we-do/'}><li>What We do</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/what-we-offer/'}><li>What We Offer</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/testimonials/'}><li>Testimonials</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/contact-us/'}><li>Contact Us</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/request-quote/'}><li>Request For Quote</li></NavLink>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="footer-widget">
                                    <div className="footer-widget-title">
                                        <h3>What We Do</h3>
                                    </div>

                                    <div className="footer-widget-content">
                                        <ul className='footer-ul'>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/biotech/'}><li>Biotech</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/dimensional/'}><li>Dimensional</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/electronic/'}><li>Electronic</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/mass-balances/'}><li>Mass & Balances</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/industrial/'}><li>Industrial</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/mechanical/'}><li>Mechanical</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/temperature-humidity/'}><li>Temperature</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/torque/'}><li>Torque</li></NavLink>
                                            <NavLink to={'https://develop.stackblue.com/lawcalibration/pressure/'}><li>Pressure</li></NavLink>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="footer-widget">
                                    <div className="footer-widget-title">
                                        <h3>Head Office</h3>
                                    </div>

                                    <div className="footer-widget-content">
                                        <ul className="contact-info">
                                            <li>
                                                <span className="icon">
                                                    <i className='fa fa-building'></i>
                                                </span>
                                                <span>
                                                    <b>Address</b> <br />
                                                    41 Spring Hill Rd, Saco, ME 04072
                                                </span>
                                            </li>

                                            <li>
                                                <span className="icon">
                                                    <i className='fa fa-phone'></i>
                                                </span>
                                                <span>
                                                    <b>Phone</b><br />
                                                    207-494-7538
                                                </span>
                                            </li>

                                            <li>
                                                <span className="icon">
                                                    <i className='fa fa-envelope'></i>
                                                </span>
                                                <span>
                                                    <b>Email</b><br />
                                                    info@lawcalibration.com
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <ul className="social-icons">
                                        <li>
                                            <i className='fa fa-linkedin'></i>
                                        </li>
                                        <li>
                                            <i className='fa fa-facebook'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default RequestForm2
